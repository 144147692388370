<template>
  <div>
    <b-card>
      <h4>KYC Verification</h4>
      <b-row>
        <b-col cols="12 pt-2">
          <h5>
            ID Documents:
          </h5>
          <p class="text-danger">
            {{ errorMessage }}
          </p>
          <document-image
            ref="idCardImage"
            :document-options="idCardOptions"
            :document-images="documentImages"
            :info-text="'Please upload at least one ID document.'"
            @documentRemoved="(id)=>{documentRemoved(id,'document')}"
          />
        </b-col>

        <b-col cols="12 pb-2 pt-3">
          <h5 class="">
            Proof Of Address:
          </h5>
          <p class="text-danger">
            {{ proofOfAddressErrorMessage }}
          </p>
          <document-image
            ref="proofOfAddressImage"
            :info-text="'Please upload at least one document for proof of address.'"
            :document-options="proofOfAddressOptions"
            :document-images="proofOfAddressImages"
            @documentRemoved="(id)=>{documentRemoved(id,'proofOfAddress')}"
          />
        </b-col>
      </b-row>

      <b-col
        class="text-right pt-2"
        cols="12"
      >

        <b-button
          variant="success"
          :disabled="!emptyDocuments"
          @click="saveKyc"
        >
          Save Information
        </b-button>
      </b-col>
    </b-card>
  </div>
</template>
<script>
import useApollo from '@/plugins/graphql/useApollo'
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import DocumentImage from './DocumentImage.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    DocumentImage,
  },

  data() {
    return {
      idCardOptions: {
        NATIONALID: 'National ID',
        PASSPORT: 'Passport',
        DRIVINGLICENSE: 'Driving License',
        VOTERID: 'Voter ID',
        VERIFIEDFORM: 'Verified Form',
      },
      proofOfAddressOptions: {
        UTILITYBILL: 'Utility Bill',
        PROPERTYTAXRECEIPT: 'Property Tax Receipt',
        BANKSTATEMENT: 'Bank Statement',
        NATIONALID: 'National Id',
        DRIVINGLICENSE: 'Driving License',
      },
      errorMessage: '',
      proofOfAddressErrorMessage: '',
      form: {
        fullName: '',
      },
      userData: getUserData(),
      proofOfAddressImages: [],
      documentImages: [],
      isMounted: false,
    }
  },

  computed: {
    emptyDocuments() {
      if (this.isMounted) {
        const proofDocuments = this.$refs?.proofOfAddressImage?.documentImages.filter(d => !d.verifiedAt)
        const idDocuments = this.$refs?.idCardImage?.documentImages.filter(d => !d.verifiedAt)

        return (proofDocuments.length) || (idDocuments.length)
      }
      return false
    },
  },

  mounted() {
    this.getUserDocuments()
    this.getProofOfAddressImages()
    this.isMounted = true
  },

  methods: {
    getUserDocuments() {
      useApollo.users.getUserDocuments().then(res => {
        this.documentImages = res.data.me.documents.map(item => {
          delete item.__typename
          return item
        })
      })
    },

    getProofOfAddressImages() {
      useApollo.users.getProofOfAddressImages().then(res => {
        this.proofOfAddressImages = res.data.me.proofOfAddress.map(item => {
          delete item.__typename
          return item
        })
      })
    },

    documentRemoved(id, type) {
      const data = {
        userUid: this.userData.uuid,
        documentId: id,
        documentType: type,
      }
      useApollo.users.deleteUserDocuments(data).then(() => {
        this.showSuccess('Document Deleted Successfully')
        this.getProofOfAddressImages()
        this.getUserDocuments()
      })
    },

    validateImages(documentImages, options) {
      let emptyDocuments = ''
      let errorMessage = ''
      documentImages.forEach(document => {
        if (!document.document) {
          let separator = ''
          if (emptyDocuments.length) {
            separator = ','
          }
          emptyDocuments += `${separator} ${options[document.documentType]}`
        }
      })

      if (emptyDocuments.length) {
        errorMessage = `Please upload image of your ${emptyDocuments.toLowerCase()}`
      }
      return errorMessage
    },

    saveKyc() {
      this.errorMessage = this.validateImages(this.$refs.idCardImage.documentImages, this.idCardOptions)
      this.proofOfAddressErrorMessage = this.validateImages(this.$refs.proofOfAddressImage.documentImages, this.proofOfAddressOptions)

      if (!this.errorMessage.length && !this.proofOfAddressErrorMessage) {
        this.updateUserDocument(this.$refs.idCardImage.documentImages)
        this.updateProofOfAddress(this.$refs.proofOfAddressImage.documentImages)
      }
    },

    updateUserDocument(documentImages) {
      const filteredDocumentImages = documentImages.filter(d => !d.verifiedAt)
      const data = {
        userUid: this.userData.uuid,
        input: filteredDocumentImages,
      }
      useApollo.users.updateUserDocument(data).then(res => {
        this.showSuccess(res.data.updateUserDocument.message)
        this.getUserDocuments()
      })
    },

    updateProofOfAddress(documentImages) {
      const filteredDocumentImages = documentImages.filter(d => !d.verifiedAt)
      const data = {
        userUid: this.userData.uuid,
        input: filteredDocumentImages,
      }
      useApollo.users.updateUserProofOfAddress(data).then(() => {
        this.getProofOfAddressImages()
      })
    },
  },
}
</script>
